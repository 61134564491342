import { DocumentItemType } from '../DocumentsView';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Document, Page } from 'react-pdf';
import React from 'react';
import './styles.css';

export type PDFViewerType = {
  document: DocumentItemType;
  width: number;
};

export const PDFViewer = (props: PDFViewerType) => {
  const [pages, setPages] = React.useState<number[]>([]);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    const array: number[] = [];
    for (let i = 0; i < numPages; i++) {
      array.push(i + 1);
    }
    setPages(array);
  }

  return (
    <div className="h-[604px] sm:h-[401px] overflow-y-auto overflow-x-hidden border-solid border-[1px] border-[#D2D2D2] rounded-[12px] flex justify-center">
      <Document
        file={`data:application/pdf;base64,${props.document.content}`}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={error => {
          console.log(error.message);
        }}
      >
        {pages.map(page => (
          <Page key={page} width={props.width} pageNumber={page} className="!w-full" />
        ))}
      </Document>
    </div>
  );
};
