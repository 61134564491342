type MessageDataType =
  | {
      status: 'contact-us';
      data: {
        message: string;
      };
    }
  | {
      status: 'ready';
      data: {
        message: string;
      };
    }
  | {
      status: 'error';
      data: {
        code: number;
        message: string;
      };
    }
  | {
      status: 'timeout';
      data: {
        message: string;
      };
    }
  | {
      status: 'size';
      data: {
        height: number;
      };
    }
  | {
    status: 'return'
  }
  | {
      status: 'success';
      data: {
        contractId: number;
        padId: number;
      };
    }
  | {
      status: 'download-file';
      data: {
        name: string;
        content: string;
      };
    }
  | {
      status: 'marketing';
      payload:
        | {
            status: 'screen';
            data: {
              categoryName: string;
              pathName: string;
              properties?: any;
            };
          }
        | {
            status: 'track';
            data: {
              event: string;
              properties?: any;
            };
          };
    };

export const postMessageSender = (data: MessageDataType) => {
  try {
    const tmp = JSON.stringify(data);
    window.parent.postMessage(tmp, '*');
  } catch (error) {
    console.warn('postMessageSender:', error);
  }
};
