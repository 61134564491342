import * as React from 'react';
import { LabeledValue, Price, SummaryBox, SummaryDivider } from '@brainfinance/icash-component-library';

import BackgroundImage from '../../images/symbol-instant-loans-half-left.svg';

type SummaryPropsType = {
  loanDetails: number;
  totalAmountOfPayments: number;
  repayments: number;
};

function Summary(props: SummaryPropsType) {
  return (
    <div className="flex py-[5rem] justify-center mx-auto">
      <SummaryBox
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundPosition: '198px 53px',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '190px auto',
        }}
      >
        <h4 className="summary-box--title">Loan details</h4>
        <div className="space-y-[18px] ml-[6px]">
          <LabeledValue label="Loan details">
            <Price amount={props.loanDetails} size="medium" />
          </LabeledValue>
          <LabeledValue label="Repayments">{props.repayments}</LabeledValue>
        </div>
        <SummaryDivider />
        <div className="align-text-bottom ml-[6px]">
          <LabeledValue label="Total amount of payments" size="large">
            <Price amount={props.totalAmountOfPayments} size="large" />
          </LabeledValue>
        </div>
      </SummaryBox>
    </div>
  );
}

export function SummaryMobile(props: SummaryPropsType) {
  return (
    <div className="flex flex-1 w-[100%]">
      <SummaryBox
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundPosition: '100% 50%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100px auto',
          width: '100%',
          maxWidth: '100%',
        }}
      >
        <h4 className="summary-box--title">Loan details</h4>
        <div className="space-y-[18px] ml-[6px]">
          <LabeledValue label="Loan details" size="small">
            <Price amount={props.loanDetails} size="small" />
          </LabeledValue>
          <LabeledValue label="Total amount of payments" size="small">
            <Price amount={props.totalAmountOfPayments} size="small" />
          </LabeledValue>
        </div>
      </SummaryBox>
    </div>
  );
}

export default Summary;
