import * as React from 'react';
import './style.css';
import { fakeDownloadWithContentBase64 } from '../../../Helpers/DownloadFile';

export type DocumentItemType = {
  title: string;
  content: string;
};

type DocumentsViewPropsType = {
  loanContract: DocumentItemType;
  loanPad: DocumentItemType;
  loading: boolean;
};

function DocumentsView(props: DocumentsViewPropsType) {
  const onDownloadDocument = async (document: DocumentItemType) => {
    if (props.loading) {
      return;
    }
    await fakeDownloadWithContentBase64(document.content, document.title);
  };

  return (
    <p className="caption">
      You can print this agreement (
      <span onClick={() => onDownloadDocument(props.loanContract)} className="underline cursor-pointer">
        Loan contract
      </span>
      ,{' '}
      <span onClick={() => onDownloadDocument(props.loanPad)} className="underline cursor-pointer">
        Loan PAD
      </span>
      ) for review purposes only. Note that the agreement must be signed electronically, we do not accept manually
      signed copies.
    </p>
  );
}

export default DocumentsView;
