import StackdriverErrorReporter from 'stackdriver-errors-js';

export let errorHandler: StackdriverErrorReporter | null = new StackdriverErrorReporter();
if (
  typeof window !== 'undefined' &&
  process.env.REACT_APP_ICASH_ZONE_ERROR_REPORTING_KEY &&
  process.env.REACT_APP_GOOGLE_PROJECT_ID
) {
  errorHandler.start({
    key: process.env.REACT_APP_ICASH_ZONE_ERROR_REPORTING_KEY,
    projectId: process.env.REACT_APP_GOOGLE_PROJECT_ID,
    service: 'instantloan-contract-signature',
  });
} else {
  errorHandler = null;
  if (typeof window !== 'undefined') {
    console.warn('Cannot init stackdriver-error.');
  }
}

export const useStackDriver = () => {
  const updateUser = (email: string | undefined) => {
    if (errorHandler) {
      errorHandler.setUser(email);
    }
  };

  return { updateUser, errorHandler };
};
