import { DashboardSkeleton } from '@brainfinance/icash-component-library';
import { PropsWithChildren } from 'react';

type LoaderProps = PropsWithChildren<{
  loading: boolean;
}>;

export function Loader(props: LoaderProps) {
  if (props.loading)
    return (
      <div>
        {/* Preload font when fetching data. */}
        <div style={{ opacity: 0 }}>
          <span style={{ fontFamily: 'Poppins' }}></span>
          <span style={{ fontFamily: 'Poppins', fontWeight: 600 }}></span>
          <span style={{ fontFamily: 'Poppins', fontWeight: 500 }}></span>
          <span style={{ fontFamily: 'Poppins', fontWeight: 400 }}></span>
          <span style={{ fontFamily: 'Poppins', fontWeight: 300 }}></span>
        </div>
        <DashboardSkeleton />
      </div>
    );

  return <>{props.children}</>;
}
