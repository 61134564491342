import { ContractsDetails } from '../Parsers/ContractParser';
import { SignDetails } from '../Parsers/SignParser';
import { UserDetails } from '../Parsers/UserParser';
import { postMessageSender } from '../PostMessage';
import { InstantLoanDashboard } from './types';

const getUrl = () => {
  if (process.env.REACT_APP_MARIA_BASE_URL === undefined || process.env.REACT_APP_MARIA_BASE_URL === '') {
    console.warn('process.env.REACT_APP_MARIA_BASE_URL not set.');
  }
  return process.env.REACT_APP_MARIA_BASE_URL;
};

const url = getUrl();

const getDashboardHeaders = (token: string): any => {
  return {
    Authorization: `Bearer ${token}`,
    'Application-ID': 'Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*',
    'Content-Type': 'application/json',
  };
};

export const getDashboard = async (token?: string): Promise<InstantLoanDashboard | null> => {
  try {
    const res = await fetch(url + '/user/dashboard/instantloan', {
      method: 'GET',
      headers: getDashboardHeaders(token ?? ''),
      credentials: process.env.REACT_APP_SANDBOX_ENABLE === 'true' ? 'include' : undefined,
    });
    if (res.ok) {
      const jsonData = await res.json();

      const result = jsonData.result;
      const dashboard: InstantLoanDashboard = result;

      return dashboard;
    } else {
      const jsonData = await res.json();

      postMessageSender({
        status: 'error',
        data: jsonData.error,
      });
    }
  } catch (error) {
    console.warn('error: ', error);
    postMessageSender({
      status: 'error',
      data: {
        code: 500,
        message:
          'Sorry, something went wrong and we were unable to complete the signature step. Please try again later or contact support.',
      },
    });
  }
  return null;
};

const getContractsHeaders = (token: string): any => {
  return {
    Authorization: `Bearer ${token}`,
    'Application-ID': 'Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*',
  };
};

export const getContracts = async (loanId?: number, token?: string): Promise<ContractsDetails | null> => {
  try {
    const res = await fetch(url + `/contract/blank/${loanId ?? 1}/binary`, {
      method: 'GET',
      headers: getContractsHeaders(token ?? ''),
      credentials: process.env.REACT_APP_SANDBOX_ENABLE === 'true' ? 'include' : undefined,
    });
    if (res.ok) {
      const jsonData = await res.json();
      if (jsonData.result) {
        const result = jsonData.result;
        const contracts: ContractsDetails = result;

        return contracts;
      }
      return null;
    } else {
      const jsonData = await res.json();

      postMessageSender({
        status: 'error',
        data: jsonData.error,
      });
    }
  } catch (error) {
    console.warn('error: ', error);
    postMessageSender({
      status: 'error',
      data: {
        code: 500,
        message:
          'Sorry, something went wrong and we were unable to complete the signature step. Please try again later or contact support.',
      },
    });
  }
  return null;
};

type SignBodyType = {
  loanId: number;
  signature1: string;
  initial: string;
  signature2: string;
};

export const sign = async (
  token: string,
  data: SignBodyType
): Promise<{ result?: SignDetails; error?: { message: string; code: number } }> => {
  for (let i = 0; i < 2; i++) {
    try {
      const res = await fetch(url + '/contract/sign', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Application-ID': 'Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*',
          'Content-Type': 'application/json',
        },
        credentials: process.env.REACT_APP_SANDBOX_ENABLE === 'true' ? 'include' : undefined,
        body: JSON.stringify({
          loanId: data.loanId,
          signature1: data.signature1,
          signature2: data.signature2,
          initial: data.initial,
        }),
      });

      if (res.status === 409) {
        postMessageSender({
          status: 'return'
        });
      }

      const jsonData = await res.json();

      if (jsonData.error) {
        console.error('Response error: ', jsonData)
        return {
          error: {
            code: jsonData.error.code ?? 0,
            message:
              'Sorry, something went wrong and we were unable to complete the signature step. Please try again later or contact support.',
          },
        };
      }

      if (jsonData.result) {
        const result = jsonData.result;
        const signDetails: SignDetails = result;

        return {
          result: signDetails,
        };
      }
    } catch (error) {
      console.warn('error: ', error);
    }
    
    console.log('Retrying to sign...');
  }

  return {
    error: {
      code: 500,
      message:
        'Sorry, something went wrong and we were unable to complete the signature step. Please try again later or contact support.',
    },
  };
};

const getUserInfoHeaders = (token: string): any => {
  return {
    Authorization: `Bearer ${token}`,
    'Application-ID': 'Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*',
  };
};

export const getUserInfo = async (token?: string): Promise<UserDetails | null> => {
  try {
    const res = await fetch(url + '/user/info', {
      method: 'GET',
      headers: getUserInfoHeaders(token ?? ''),
      credentials: process.env.REACT_APP_SANDBOX_ENABLE === 'true' ? 'include' : undefined,
    });
    if (res.ok) {
      const jsonData = await res.json();
      if (jsonData.result) {
        const result = jsonData.result;
        const user: UserDetails = result;

        return user;
      }
      return null;
    } else {
      const jsonData = await res.json();

      postMessageSender({
        status: 'error',
        data: jsonData.error,
      });
    }
  } catch (error) {
    console.warn('error: ', error);
    postMessageSender({
      status: 'error',
      data: {
        code: 500,
        message:
          'Sorry, something went wrong and we were unable to complete the signature step. Please try again later or contact support.',
      },
    });
  }
  return null;
};
