import { postMessageSender } from '../PostMessage';
import { isMobile } from 'react-device-detect';

export const fakeDownload = async (blob: Blob, fileName: string) => {
  const href = await URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const fakeDownloadWithContentBase64 = async (data: string, fileName: string) => {
  try {
    const content = `data:application/pdf;base64,${data}`;
    const fileNameFormated = fileName + '.pdf';
    const r = await fetch(content);
    const blob = await r.blob();

    const file = new File([blob], fileNameFormated);

    if (isMobile && window.navigator.canShare && window.navigator.canShare({ files: [file] })) {
      try {
        await window.navigator.share({
          files: [file],
        });
      } catch (error) {
        console.warn('Sharing failed :', error);
      }
    } else {
      postMessageSender({
        status: 'download-file',
        data: {
          name: `${fileName}.pdf`,
          content: data,
        },
      });
    }
  } catch (error) {
    postMessageSender({
      status: 'download-file',
      data: {
        name: `${fileName}.pdf`,
        content: data,
      },
    });
  }
};
