import { useRef } from "react";
import LogRocket from "logrocket";

export function useLogRocket() {
  const isInit = useRef<boolean>(false);

  const init = () => {
    if (process.env.REACT_APP_LOG_ROCKET_LOG_ID) {
      LogRocket.init(process.env.REACT_APP_LOG_ROCKET_LOG_ID, {
        mergeIframes: true,
        parentDomain: process.env.REACT_APP_ICASH_BASE_URL
      });
      isInit.current = true;
    } else {
      console.warn("REACT_APP_LOG_ROCKET_LOG_ID not set.");
    }
  };

  return { init };
}
